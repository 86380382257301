import {
  Card,
  IndexTable,
  InlineStack,
  Page,
  Pagination,
  Tabs,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInsurancePayments } from "./hooks/useInsurancePayments";
import { InsurancePayment } from "../../../types/common.types";
import { formatDate, formatMoney } from "../../../helpers/helpers";
import InsurancePaymentModal from "./NewInsurancePaymentModal";

export default function InsurancePage() {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [insurancePaymentModalActive, setInsurancePaymentModalActive] =
    useState(false);
  const [status, setStatus] = useState("PENDING");
  const [selectedPayment, setSelectedPayment] =
    useState<InsurancePayment | null>(null);

  const columns = [
    { label: t("date") },
    { label: t("amount") },
    { label: t("status") },
  ];

  const tabs = [
    {
      id: "PENDING",
      content: t("pending"),
    },
    {
      id: "PAID",
      content: t("paid"),
    },
    {
      id: "ALL",
      content: t("all"),
    },
  ];

  const { minPage, maxPage, isFetching, page, setPage, payments } =
    useInsurancePayments(status);

  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
    setStatus(tabs[selectedTabIndex].id);
  }, []);

  const rowMarkup = payments.map((payment: InsurancePayment, index) => (
    <IndexTable.Row
      id={payment.id as string}
      key={payment.id}
      position={index}
      onClick={() => {
        setSelectedPayment(payment);
        setInsurancePaymentModalActive(true);
      }}
    >
      <IndexTable.Cell>{formatDate(payment.date, true, true)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(payment.amount)}</IndexTable.Cell>
      <IndexTable.Cell>{t(payment.status.toLowerCase())}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      title={t("insurance")}
      fullWidth
      primaryAction={{
        content: t("add"),
        onAction: () => setInsurancePaymentModalActive(true),
      }}
    >
      <Card padding="0">
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange} />

        <IndexTable
          resourceName={{
            singular: t("payment"),
            plural: t("payments"),
          }}
          itemCount={payments.length}
          loading={isFetching}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
        <div
          style={{
            borderTop: ".0625rem solid var(--p-border-subdued)",
            margin: "auto",
            padding: "1.5rem 1rem",
          }}
        >
          <InlineStack align="center">
            <Pagination
              hasPrevious={page > minPage}
              hasNext={page < maxPage}
              onPrevious={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
            />
          </InlineStack>
        </div>
        <InsurancePaymentModal
          active={insurancePaymentModalActive}
          setActive={() => {
            setSelectedPayment(null);
            setInsurancePaymentModalActive(false);
          }}
          payment={selectedPayment}
        />
      </Card>
    </Page>
  );
}
