import {
  IndexTable,
  Page,
  Text,
  useSetIndexFiltersMode,
  IndexFilters,
  TabProps,
  Card,
  Link,
  DatePicker,
} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useTranslation } from "react-i18next";
import { useRents } from "./hooks/useRents";
import { Rent } from "../../../types/common.types";
import { formatDate, formatMoney } from "../../../helpers/helpers";

export default function RentsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mode, setMode } = useSetIndexFiltersMode();
  const [selected, setSelected] = useState(0);
  const [status, setStatus] = useState("active");
  const [queryValue, setQueryValue] = useState("");

  const [filterToDate, setFilterToDate] = useState({ month: 1, year: 2018 });
  const [selectedFilterToDates, setSelectedFilterToDates] = useState({
    start: new Date("Wed Feb 07 2018 00:00:00 GMT-0500 (EST)"),
    end: new Date("Sat Feb 10 2018 00:00:00 GMT-0500 (EST)"),
  });

  const handleMonthToDateChange = useCallback(
    (month: number, year: number) => setFilterToDate({ month, year }),
    [],
  );

  const { minPage, maxPage, isFetching, page, setPage, rents } = useRents(
    10,
    queryValue,
    status,
  );

  const tabs: TabProps[] = [
    {
      id: "active",
      content: t("active"),
      isLocked: true,
    },
    {
      id: "unpaid",
      content: t("unpaid"),
    },
    {
      id: "all",
      content: t("all"),
    },
  ];
  const columns = [
    { label: t("car") },
    { label: t("customer") },
    { label: t("has_second_driver") },
    { label: t("price_per_day") },
    { label: t("number_of_days") },
    { label: t("total") },
    { label: t("amount_paid") },
    { label: t("amount_left") },
    { label: t("from_date") },
    { label: t("to_date") },
  ];
  const filters = [
    {
      key: "from_date",
      label: t("from_date"),
      filter: (
        <DatePicker
          month={filterToDate.month}
          year={filterToDate.year}
          selected={selectedFilterToDates}
          onChange={setSelectedFilterToDates}
          allowRange
          onMonthChange={handleMonthToDateChange}
        />
      ),
      shortcut: true,
    },
  ];

  const handleFiltersQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    [],
  );

  const handleTabChange = useCallback((value: number) => {
    setSelected(value);
    setStatus(tabs[value].id);
  }, []);

  const rowMarkup = rents.map((rent: Rent, index) => (
    <IndexTable.Row id={rent.id as string} key={rent.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          dataPrimaryLink
          onClick={() => navigate(`/admin/rents/${rent.id}`)}
        >
          <Text fontWeight="bold" as="span">
            {`${rent.car?.name} (${rent?.car?.license_plate})`}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={() => navigate(`/admin/customers/${rent.customer?.id}`)}>
          <Text fontWeight="bold" as="span">
            {rent.customer?.name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {rent?.second_driver_id ? t("yes") : t("no")}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatMoney(rent.price_per_day as number)}
      </IndexTable.Cell>
      <IndexTable.Cell>{rent.number_of_days}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(rent.total as number)}</IndexTable.Cell>
      <IndexTable.Cell>
        {formatMoney(rent.amount_paid as number)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatMoney(rent.amount_left as number)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatDate(rent.from_date, false, true)}
      </IndexTable.Cell>
      <IndexTable.Cell>{formatDate(rent.to_date, false, true)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("rents")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          navigate("/admin/rents/new");
        },
      }}
    >
      <Card padding="0">
        <IndexFilters
          queryValue={queryValue}
          queryPlaceholder={t("searching")}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => setQueryValue("")}
          cancelAction={{
            onAction: () => {
              setQueryValue("");
            },
            disabled: false,
            loading: false,
          }}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          filters={filters}
          onClearAll={() => {}}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
          hideFilters
        />
        <IndexTable
          resourceName={{
            singular: t("rent"),
            plural: t("rents"),
          }}
          itemCount={rents.length}
          loading={isFetching}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          selectable={false}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
      </Card>
    </Page>
  );
}
