import { FormLayout, Modal } from "@shopify/polaris";
import { useForm } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import DatePickerInput from "../../../../../componenets/DatePickerInput";
import TextFieldInput from "../../../../../componenets/TextFieldInput";
import { Agent, RentPayment } from "../../../../../types/common.types";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useAddRentPayment } from "./hooks/useAddRentPayment";
import SelectInput from "../../../../../componenets/SelectInput";
import { useAgents } from "../../../Agents/hooks/useAgents";

interface Props {
  rentId: string;
  carId: string;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    date: yup.date().required(),
    amount: yup.number().required(),
    taken_by_agent_id: yup.string().required(),
    notes: yup.string().nullable(),
  })
  .required();

export default function AddRentPaymentModal({
  rentId,
  carId,
  active,
  setActive,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { control, reset, handleSubmit, setValue } = useForm<RentPayment>({
    defaultValues: {
      date: new Date(),
    },
    resolver: yupResolver(schema),
  });
  const { isLoading: isAgentsLoading, agents } = useAgents(100);
  const addRentPayment = useAddRentPayment();

  const handleClose = () => {
    reset();

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (payment: RentPayment) => {
    setLoading(true);

    try {
      await addRentPayment({
        ...payment,
        rent_id: rentId,
        car_id: carId,
      });

      await queryClient.invalidateQueries([queryKeysConstants.rents, rentId]);

      reset({
        date: new Date(),
        amount: 0,
        notes: "",
      });
      handleClose();

      onShowToast(t("payment_saved"), false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (agents.length) setValue("taken_by_agent_id", agents[0].id as string);
  }, [agents]);

  return (
    <Modal
      open={active}
      title={t("new_payment")}
      onClose={handleClose}
      primaryAction={{
        content: t("save"),
        onAction: handleSubmit(onHandleSubmit),
        loading,
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction() {
            handleClose();
          },
        },
      ]}
      loading={isAgentsLoading}
    >
      <Modal.Section>
        <FormLayout>
          <DatePickerInput control={control} name="date" label={t("date")} />
          <TextFieldInput control={control} name="amount" label={t("amount")} />
          <SelectInput
            control={control}
            name="taken_by_agent_id"
            label={t("taken_by")}
            options={agents.map((agent: Agent) => ({
              label: agent.name,
              value: agent.id,
            }))}
          />
          <TextFieldInput
            control={control}
            name="notes"
            label={t("notes")}
            multiline={4}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
