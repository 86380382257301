import { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../../../constants/queryKeys.constants";
import { CarDocument, Page } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function getCarDocuments(
  carId: string,
  status: string,
  query: string,
  page = 1,
  size = 10,
): Promise<Page<CarDocument>> {
  const { data } = await axiosInstance.get(`/cars/${carId}/documents`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: {
      status,
      query,
      page,
      size,
    },
  });
  return data;
}

interface useCarsProps {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  carDocuments: CarDocument[];
}

export function useCarDocuments(
  carId: string,
  status: string,
  query: string,
  size = 10,
): useCarsProps {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    [
      queryKeysConstants.cars,
      carId,
      "car-documents",
      status,
      query,
      page,
      size,
    ],
    () => getCarDocuments(carId, status, query, page, size),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    carDocuments: data?.items || [],
  };
}
