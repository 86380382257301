import moment from "moment";
import "moment/locale/fr";
import { DateRange } from "../componenets/DateRangeSelectorPopup";
import { Agent, Car, Customer } from "../types/common.types";

function getQuarterRange(year: string, quarter: number) {
  const start = moment(year).quarter(quarter).startOf("quarter");

  const end = moment(year).quarter(quarter).endOf("quarter");

  return { start: start.toDate(), end: end.toDate() };
}

const TODAY = new Date(moment().toDate().setHours(0, 0, 0, 0));
const YESTERDAY = new Date(
  new Date(moment().subtract(1, "d").toDate()).setHours(0, 0, 0, 0),
);

const Q4 = getQuarterRange("2024", 4);

export const dateRanges: DateRange[] = [
  {
    title: "today",
    alias: "today",
    period: {
      since: TODAY,
      until: TODAY,
    },
  },
  {
    title: "yesterday",
    alias: "yesterday",
    period: {
      since: YESTERDAY,
      until: YESTERDAY,
    },
  },
  {
    title: "last_7_days",
    alias: "last_7_days",
    period: {
      since: new Date(
        new Date(moment().subtract(7, "d").toDate()).setHours(0, 0, 0, 0),
      ),
      until: YESTERDAY,
    },
  },
  {
    title: "current_month",
    alias: "current_month",
    period: {
      since: new Date(moment().startOf("month").toDate().setHours(0, 0, 0, 0)),
      until: new Date(moment().endOf("month").toDate()),
    },
  },
  {
    title: "last_month",
    alias: "last_month",
    period: {
      since: new Date(
        moment()
          .subtract(1, "months")
          .startOf("month")
          .toDate()
          .setHours(0, 0, 0, 0),
      ),
      until: new Date(moment().subtract(1, "months").endOf("month").toDate()),
    },
  },
  {
    title: "year_to_date",
    alias: "year_to_date",
    period: {
      since: new Date(moment().startOf("year").toDate().setHours(0, 0, 0, 0)),
      until: new Date(TODAY.setHours(23, 59, 59, 59)),
    },
  },
  {
    title: "q4",
    alias: "q4",
    period: {
      since: new Date(Q4.start),
      until: new Date(Q4.end),
    },
  },
];

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatMoney(value: number): string {
  return new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency: "MAD",
  }).format(value);
}

export function formatDate(
  value: Date | undefined,
  onlyDate = false,
  simpleFormat = false,
  forceFromNow = false,
): string {
  if (forceFromNow || (moment.utc(value) > moment() && !simpleFormat)) {
    return capitalizeFirstLetter(moment.utc(value).locale("fr-FR").fromNow());
  }

  let format = "MMMM Do YYYY, HH:mm";

  if (onlyDate) {
    format = "MMMM Do YYYY";
  }

  return capitalizeFirstLetter(
    moment.utc(value).locale("fr-FR").format(format),
  );
}

export function formatDistance(value: any): string {
  const formatedValue = value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });

  return `${formatedValue} KM`;
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + parseInt(days.toString(), 10));

  return result;
}

export function timezoneOffsetDate(d: Date): Date {
  const offset = new Date().getTimezoneOffset();

  return new Date(d.getTime() - offset * 60 * 1000);
}

export function getTimeString(d: Date): string {
  const date = timezoneOffsetDate(d);

  const dateSplit = date.toISOString().split("T")[1].split(":");
  return `${dateSplit[0]}:${dateSplit[1]}`;
}

export function getTwoDatesDaysDifference(date1: Date, date2: Date): number {
  const differenceInTime = date1.getTime() - date2.getTime();

  return Math.round(differenceInTime / (1000 * 3600 * 24));
}

export function isEmpty(value: string | any[]) {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return value === "" || value == null;
}

export function getAgentNameById(agents: Agent[], agentId: string): string {
  // eslint-disable-next-line no-restricted-syntax
  for (const agent of agents) {
    if (agent.id === agentId) {
      return agent.name;
    }
  }

  return agents[0].name;
}

export function getCarNameById(cars: Car[], carId: string): string {
  // eslint-disable-next-line no-restricted-syntax
  for (const car of cars) {
    if (car.id === carId) {
      return `${car.name} (${car.license_plate})`;
    }
  }

  return `Unknown`;
}

export function getCustomerByName(
  customers: Customer[],
  name: string,
): Customer | null {
  // eslint-disable-next-line no-restricted-syntax
  for (const customer of customers) {
    if (customer.name === name) {
      return customer;
    }
  }

  return null;
}
