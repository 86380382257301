import {
  Card,
  IndexTable,
  Page,
  Text,
  Link,
  ChoiceList,
  IndexFilters,
  useSetIndexFiltersMode,
  IndexFiltersMode,
  IndexFiltersProps,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useCars } from "./hooks/useCars";
import { Car } from "../../../types/common.types";
import NewCarModal from "./NewCarModal";
import {
  formatDistance,
  formatMoney,
  getCarNameById,
} from "../../../helpers/helpers";

export default function CarsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);
  const appliedFilters: IndexFiltersProps["appliedFilters"] = [];

  const [selectedCars, setSelectedCars] = useState<string[] | undefined>(
    undefined,
  );
  const [carModalActive, setCarModalActive] = useState(false);

  const [filterCars, setFilterCars] = useState<Car[]>([]);
  const { minPage, maxPage, isFetching, page, setPage, cars, totalAmount } =
    useCars(10, selectedCars);

  const columns = [
    t("car_name"),
    t("car_license_plate"),
    t("traveled_distance"),
    t("next_oil_change_at"),
    t("monthly_payment"),
    t("average_monthly_cost"),
  ];

  const handleSelectedCarsChange = useCallback(
    (value: string[]) => setSelectedCars(value),
    [],
  );
  const handleSelectedCarsRemove = useCallback(
    () => setSelectedCars(undefined),
    [],
  );

  const handleFiltersClearAll = useCallback(() => {
    handleSelectedCarsRemove();
  }, [handleSelectedCarsRemove]);

  const filters = [
    {
      key: "cars",
      label: t("cars"),
      filter: (
        <ChoiceList
          title={t("cars")}
          titleHidden
          choices={filterCars.map((car) => ({
            label: `${car.name} (${car.license_plate})`,
            value: car.id as string,
          }))}
          selected={selectedCars || []}
          onChange={handleSelectedCarsChange}
          allowMultiple
        />
      ),
    },
  ];

  if (selectedCars && !isEmpty(selectedCars)) {
    appliedFilters.push({
      key: "cars",
      label: (selectedCars as string[])
        .map((car) => getCarNameById(cars, car))
        .join(", "),
      onRemove: handleSelectedCarsRemove,
    });
  }

  useEffect(() => {
    if (!filterCars.length) {
      setFilterCars(cars);
    }
  }, [cars]);

  const rowMarkup = cars.map((car: Car, index) => (
    <IndexTable.Row id={car.id as string} key={car.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link dataPrimaryLink onClick={() => navigate(`/admin/cars/${car.id}`)}>
          <Text fontWeight="bold" as="span">
            {car.name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{car.license_plate}</IndexTable.Cell>
      <IndexTable.Cell>{formatDistance(car.traveled_distance)}</IndexTable.Cell>
      <IndexTable.Cell>
        {formatDistance(car.next_oil_change_at)}
      </IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(car.monthly_payment)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(car.average_monthly_cost)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("cars")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          setCarModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <IndexFilters
          hideQueryField
          mode={mode}
          setMode={setMode}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={() => {}}
          onQueryClear={() => {}}
          onClearAll={handleFiltersClearAll}
          selected={0}
          tabs={[]}
        />
        <IndexTable
          resourceName={{
            singular: t("car"),
            plural: t("cars"),
          }}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          itemCount={cars.length}
          loading={isFetching}
          selectable={false}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          <IndexTable.Row rowType="subheader" position={0} id="total">
            <IndexTable.Cell>{t("total")}</IndexTable.Cell>
            <IndexTable.Cell />
            <IndexTable.Cell />
            <IndexTable.Cell />
            <IndexTable.Cell>{formatMoney(totalAmount)}</IndexTable.Cell>
            <IndexTable.Cell />
          </IndexTable.Row>
          {rowMarkup}
        </IndexTable>
      </Card>
      <NewCarModal active={carModalActive} setActive={setCarModalActive} />
    </Page>
  );
}
