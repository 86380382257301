import { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAccessToken } from "../../../../services/auth.service";
import { axiosInstance } from "../../../../services/axios.service";
import { CompanyDocument, Page } from "../../../../types/common.types";
import queryKeysConstants from "../../../../constants/queryKeys.constants";

async function getCompanyDocuments(
  page = 1,
  size = 10,
): Promise<Page<CompanyDocument>> {
  const { data } = await axiosInstance.get(`/company_documents/`, {
    params: {
      page,
      size,
    },
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface useCarsProps {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  companyDocuments: CompanyDocument[];
}

export function useCompanyDocuments(size = 10): useCarsProps {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.company_documents, page, size],
    () => getCompanyDocuments(page, size),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    companyDocuments: data?.items || [],
  };
}
