import { Autocomplete, Icon } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { useState, useCallback } from "react";
import { OptionDescriptor } from "@shopify/polaris/build/ts/src/types";
import { useTranslation } from "react-i18next";
import { useController } from "react-hook-form";

interface Props {
  control: any;
  name: string;
  label: string;
  options: OptionDescriptor[];
  onUpdate: (value: string) => void;
  loading: boolean;
  onSelection?: (selectedOption: string) => void;
}

export default function AutoCompleteInput({
  control,
  name,
  label,
  options,
  onUpdate,
  loading = false,
  onSelection,
}: Props) {
  const { t } = useTranslation();
  const { field, formState } = useController({ name, control });

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const OnUpdateText = (value: string) => {
    setInputValue(value);

    onUpdate(value);
  };

  const OnUpdateSelection = useCallback(
    (selected: string[]) => {
      const selectedOption = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem);
        });

        return matchedOption && matchedOption;
      });

      setSelectedOptions(selected);

      if (onSelection) onSelection(selectedOption[0]?.value as string);

      setInputValue((selectedOption[0]?.label as string) || "");

      field.onChange(selectedOption[0]?.value || "");
    },
    [options],
  );

  const textField = (
    <Autocomplete.TextField
      onChange={OnUpdateText}
      label={label}
      value={inputValue}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder={t("search")}
      autoComplete="off"
      error={formState.errors[name]?.message as any}
    />
  );

  return (
    <div>
      <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={OnUpdateSelection}
        textField={textField}
        loading={loading}
      />
    </div>
  );
}
