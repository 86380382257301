import { Frame, Navigation } from "@shopify/polaris";
import {
  PersonFilledIcon,
  ContractFilledIcon,
  CashDollarFilledIcon,
  ChartVerticalFilledIcon,
  DeliveryFilledIcon,
  ShieldCheckMarkIcon,
  RefreshIcon,
  FileFilledIcon,
  SettingsIcon,
  PersonRemoveIcon,
  AppsFilledIcon,
  NoteIcon,
  PointOfSaleIcon,
  BookIcon,
} from "@shopify/polaris-icons";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import logo from "../config/logo";
import Header from "../componenets/Header";
import { ToastAlert } from "../componenets/ToastAlert";
import CarsPage from "../pages/AdminDashboard/Cars/CarsPage";
import RentsPage from "../pages/AdminDashboard/Rents/RentsPage";
import NewRentPage from "../pages/AdminDashboard/Rents/NewRentPage";
import CarPage from "../pages/AdminDashboard/Cars/CarPage";
import CustomersPage from "../pages/AdminDashboard/Customers/CustomersPage";
import NewCustomerPage from "../pages/AdminDashboard/Customers/NewCustomerPage";
import CustomerPage from "../pages/AdminDashboard/Customers/CustomerPage";
import RentPage from "../pages/AdminDashboard/Rents/RentPage";
import ReservationsPage from "../pages/AdminDashboard/Reservations/ReservationsPage";
import MiscellaneousChargesPage from "../pages/AdminDashboard/MiscellaneousCharges/MiscellaneousChargesPage";
import InsurancePage from "../pages/AdminDashboard/Insurance/Insurance";
import RecurringChargesPage from "../pages/AdminDashboard/RecurringCharges/RecurringChargesPage";
import RecurringChargePage from "../pages/AdminDashboard/RecurringCharges/RecurringChargePage";
import AgentsPage from "../pages/AdminDashboard/Agents/AgentsPage";
import AgentPage from "../pages/AdminDashboard/Agents/AgentPage";
import MonthlyStatsPage from "../pages/AdminDashboard/Analytics/MonthlyStatsPage";
import OverallStatsPage from "../pages/AdminDashboard/Analytics/OverallStatsPage";
import SettingsPage from "../pages/AdminDashboard/Settings/SettingsPage";
import BlacklistPage from "../pages/AdminDashboard/Blacklist/BlacklistPage";
import ProfilePage from "../pages/AdminDashboard/Profile/ProfilePage";
import RevenuePage from "../pages/AdminDashboard/Analytics/RevenuePage";
import InvoicesPage from "../pages/AdminDashboard/Invoices/InvoicesPage";
import NewInvoicePage from "../pages/AdminDashboard/Invoices/NewInvoicePage";
import InvoicePage from "../pages/AdminDashboard/Invoices/InvoicePage";
import InfractionsPage from "../pages/AdminDashboard/Infractions/InfractionsPage";
import InfractionPage from "../pages/AdminDashboard/Infractions/InfractionPage";
import { production } from "../constants/other.constants";
import ChargesPage from "../pages/AdminDashboard/Analytics/ChargesPage";
import CashRegister from "../pages/AdminDashboard/CashRegister/CashRegister";
import CompanyDocumentsPage from "../pages/AdminDashboard/CompanyDocs/CompanyDocumentsPage";

export default function AdminDashboard() {
  const location = useLocation();
  const { t } = useTranslation();
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const onMobileNavigationToggle = () => {
    setShowMobileNavigation(!showMobileNavigation);
  };

  const onHideMobileNavigation = () => {
    setShowMobileNavigation(false);
  };

  const navigationMarkup = (
    <Navigation onDismiss={onHideMobileNavigation} location={location.pathname}>
      <Navigation.Section
        items={[
          {
            url: "/admin/analytics",
            label: t("analytics"),
            icon: ChartVerticalFilledIcon,
            subNavigationItems: [
              {
                url: "/admin/analytics/monthly",
                label: t("monthly_reports"),
              },
              {
                url: "/admin/analytics/revenue",
                label: t("revenue"),
              },
              {
                url: "/admin/analytics/charges",
                label: t("charges"),
              },
            ],
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            url: "/admin/cash_register",
            label: t("cash_register"),
            icon: PointOfSaleIcon,
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            url: "/admin/cars",
            label: t("cars"),
            icon: DeliveryFilledIcon,
          },
          {
            url: "/admin/customers",
            label: t("customers"),
            icon: PersonFilledIcon,
          },
          {
            url: "/admin/rents",
            label: t("rents"),
            icon: ContractFilledIcon,
          },
          {
            url: "/admin/reservations",
            label: t("reservations"),
            icon: FileFilledIcon,
          },
          {
            label: t("miscellaneous_charges"),
            icon: CashDollarFilledIcon,
            url: "/admin/charges/miscellaneous",
          },
          {
            url: "/admin/invoices",
            label: t("invoices"),
            icon: NoteIcon,
          },
          {
            url: "/admin/infractions",
            label: t("infractions"),
            icon: NoteIcon,
            disabled: production,
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            url: "/admin/company_documents",
            label: t("company_documents"),
            icon: BookIcon,
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            url: "/admin/insurance",
            label: t("insurance"),
            icon: ShieldCheckMarkIcon,
          },
          {
            url: "/admin/recurring_charges",
            label: t("recurring_charges"),
            icon: RefreshIcon,
          },
          {
            url: "/admin/agents",
            label: t("agents"),
            icon: PersonFilledIcon,
          },
          {
            url: "/admin/blacklist",
            label: t("blacklist"),
            icon: PersonRemoveIcon,
          },
        ]}
      />
      <Navigation.Section
        fill
        items={[
          {
            url: "/admin/plugins",
            label: t("plugins"),
            icon: AppsFilledIcon,
            expanded: true,
            disabled: true,
            subNavigationItems: [
              {
                url: "/admin/plugins/valcode",
                label: t("valcode"),
              },
            ],
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            url: "/admin/settings",
            label: t("settings"),
            icon: SettingsIcon,
          },
        ]}
      />
    </Navigation>
  );

  return (
    <Frame
      logo={logo}
      topBar={<Header onNavigationToggle={onMobileNavigationToggle} />}
      navigation={navigationMarkup}
      showMobileNavigation={showMobileNavigation}
      onNavigationDismiss={onHideMobileNavigation}
    >
      <Routes>
        <Route path="/analytics" element={<OverallStatsPage />} />
        <Route path="/analytics/monthly" element={<MonthlyStatsPage />} />
        <Route path="/analytics/revenue" element={<RevenuePage />} />
        <Route path="/analytics/charges" element={<ChargesPage />} />

        <Route path="/cash_register" element={<CashRegister />} />

        <Route path="/cars" element={<CarsPage />} />
        <Route path="/cars/:carId" element={<CarPage />} />

        <Route path="/customers" element={<CustomersPage />} />
        <Route path="/customers/new" element={<NewCustomerPage />} />
        <Route path="/customers/:customerId" element={<CustomerPage />} />

        <Route path="/reservations" element={<ReservationsPage />} />

        <Route path="/rents" element={<RentsPage />} />
        <Route path="/rents/new" element={<NewRentPage />} />
        <Route path="/rents/:rentId" element={<RentPage />} />

        <Route
          path="/charges/miscellaneous"
          element={<MiscellaneousChargesPage />}
        />

        <Route path="/invoices" element={<InvoicesPage />} />
        <Route path="/invoices/new" element={<NewInvoicePage />} />
        <Route path="/invoices/:invoiceId" element={<InvoicePage />} />

        <Route path="/infractions" element={<InfractionsPage />} />
        <Route path="/infractions/:infractionId" element={<InfractionPage />} />

        <Route path="/company_documents" element={<CompanyDocumentsPage />} />

        <Route path="/insurance" element={<InsurancePage />} />

        <Route path="/recurring_charges" element={<RecurringChargesPage />} />
        <Route
          path="/recurring_charges/:recurringChargeId"
          element={<RecurringChargePage />}
        />

        <Route path="/agents" element={<AgentsPage />} />
        <Route path="/agents/:agentId" element={<AgentPage />} />

        <Route path="/blacklist" element={<BlacklistPage />} />
        <Route path="/plugins" element={<BlacklistPage />} />

        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/profile" element={<ProfilePage />} />

        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
      <ToastAlert />
    </Frame>
  );
}
