import {
  Card,
  Text,
  IndexTable,
  InlineStack,
  Button,
  Box,
  Link,
  IndexFilters,
  useSetIndexFiltersMode,
  TabProps,
  useIndexResourceState,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "@uidotdev/usehooks";
import { useCarDocuments } from "./hooks/useCarDocuments";
import { CarDocument } from "../../../../../types/common.types";
import { formatDate } from "../../../../../helpers/helpers";
import AddCarDocumentModal from "./AddCarDocumentModal";
import DeleteConfirmationDialog from "../../../../../componenets/DeleteConfirmationDialog";
import { useDeleteDocuments } from "./hooks/useDeleteDocuments";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";

export default function CarDocuments({ carId }: { carId: string }) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();

  const columns = [
    { label: t("name") },
    { label: t("added_at") },
    { label: t("expiration_date") },
    { label: t("status") },
  ];

  const tabs: TabProps[] = [
    {
      id: "IN_EFFECT",
      content: t("in_effect"),
    },
    {
      id: "ARCHIVED",
      content: t("archived"),
    },
  ];

  const [CarDocumentModalActive, setCarDocumentModalActive] = useState(false);
  const { mode, setMode } = useSetIndexFiltersMode();

  const [queryValue, setQueryValue] = useState("");
  const [status, setStatus] = useState("IN_EFFECT");
  const [query] = useDebounce([queryValue], 1000);
  const [selected, setSelected] = useState(0);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { carDocuments, isFetching } = useCarDocuments(
    carId,
    status,
    query,
    10,
  );
  const deleteDocuments = useDeleteDocuments(carId);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(
    carDocuments.map((carDocument: CarDocument) => ({ ...carDocument })),
  );

  const handleFiltersQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    [],
  );

  const handleTabChange = useCallback((value: number) => {
    setSelected(value);
    setStatus(tabs[value].id);
  }, []);

  const handleDeleteDocuments = async () => {
    setDeleteLoading(true);
    onShowToast(t("deleting_documents"), false);

    try {
      await deleteDocuments(selectedResources);
      await queryClient.invalidateQueries([
        queryKeysConstants.cars,
        carId,
        "car-documents",
      ]);

      clearSelection();
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
    }

    setDeleteLoading(false);
    setDeleteConfirmationOpen(false);
  };

  const rowMarkup = carDocuments.map(
    (carDocument: CarDocument, index: number) => (
      <IndexTable.Row
        id={carDocument.id as string}
        key={carDocument.id}
        position={index}
        selected={selectedResources.includes(carDocument.id as string)}
      >
        <IndexTable.Cell>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link target="_blank" url={carDocument.file_public_url as string}>
            {t(carDocument.type.toLowerCase())}
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatDate(carDocument.added_at, true, true)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {carDocument.expiration_date
            ? formatDate(carDocument.expiration_date, true, true)
            : t("no_expiration_date")}
        </IndexTable.Cell>
        <IndexTable.Cell>{t(carDocument.status.toLowerCase())}</IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <Card padding="0">
      <Box
        paddingInlineStart="300"
        paddingInlineEnd="300"
        paddingBlockStart="200"
      >
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            {t("documents")}
          </Text>
          <Button
            variant="plain"
            onClick={() => setCarDocumentModalActive(true)}
          >
            {t("add")}
          </Button>
        </InlineStack>
      </Box>
      <IndexFilters
        queryValue={queryValue}
        queryPlaceholder={t("searching")}
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={() => setQueryValue("")}
        cancelAction={{
          onAction: () => setQueryValue(""),
          disabled: false,
          loading: false,
        }}
        tabs={tabs}
        selected={selected}
        onSelect={handleTabChange}
        filters={[]}
        onClearAll={() => {}}
        mode={mode}
        setMode={setMode}
        canCreateNewView={false}
        disableStickyMode
      />

      <IndexTable
        resourceName={{
          singular: t("document"),
          plural: t("documents"),
        }}
        itemCount={carDocuments.length}
        headings={
          columns.map(
            (column): IndexTableHeading => ({ title: column.label }),
          ) as NonEmptyArray<IndexTableHeading>
        }
        loading={isFetching}
        selectedItemsCount={
          allResourcesSelected ? "All" : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        promotedBulkActions={[
          {
            content: t("delete_documents"),
            onAction: () => setDeleteConfirmationOpen(true),
          },
        ]}
      >
        {rowMarkup}
      </IndexTable>
      <AddCarDocumentModal
        carId={carId}
        active={CarDocumentModalActive}
        setActive={(status: boolean) => {
          setCarDocumentModalActive(status);
        }}
      />
      <DeleteConfirmationDialog
        open={deleteConfirmationOpen}
        loading={deleteLoading}
        onClose={() => setDeleteConfirmationOpen(false)}
        onDelete={handleDeleteDocuments}
      />
    </Card>
  );
}
