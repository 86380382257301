import {
  BlockStack,
  Card,
  Text,
  IndexTable,
  Box,
  Link,
  Page,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyDocuments } from "./hooks/useCompanyDocuments";
import { CompanyDocument } from "../../../types/common.types";
import { formatDate } from "../../../helpers/helpers";
import NewCompanyDocumentModal from "./NewCompanyDocumentModal";

export default function CompanyDocumentsPage() {
  const { t } = useTranslation();

  const columns = [{ label: t("name") }, { label: t("added_at") }];

  const [newCompanyDocumentModalActive, setNewCompanyDocumentModalActive] =
    useState(false);
  // selectedDocument
  const [, setSelectedDocument] = useState<CompanyDocument | null>(null);

  const { companyDocuments, isFetching } = useCompanyDocuments(10);

  const rowMarkup = companyDocuments.map(
    (companyDocument: CompanyDocument, index: number) => (
      <IndexTable.Row
        id={companyDocument.id as string}
        key={companyDocument.id}
        position={index}
        onClick={() => {
          setSelectedDocument(companyDocument);
          // setCarDocumentModalActive(true);
        }}
      >
        <IndexTable.Cell>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link target="_blank" url={companyDocument.file.public_url as string}>
            {companyDocument.label}
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatDate(companyDocument.added_at, true, true)}
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <Page
      fullWidth
      title={t("company_documents")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          setNewCompanyDocumentModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <BlockStack gap="200">
          <Box
            paddingInlineStart="300"
            paddingInlineEnd="300"
            paddingBlockStart="200"
          >
            <Text as="span" fontWeight="bold">
              {t("documents")}
            </Text>
          </Box>

          <IndexTable
            resourceName={{
              singular: t("document"),
              plural: t("documents"),
            }}
            itemCount={companyDocuments.length}
            headings={
              columns.map(
                (column): IndexTableHeading => ({ title: column.label }),
              ) as NonEmptyArray<IndexTableHeading>
            }
            loading={isFetching}
            selectable={false}
          >
            {rowMarkup}
          </IndexTable>
        </BlockStack>
        <NewCompanyDocumentModal
          active={newCompanyDocumentModalActive}
          setActive={(status: boolean) => {
            setNewCompanyDocumentModalActive(status);
          }}
        />
      </Card>
    </Page>
  );
}
