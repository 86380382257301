export interface User {
  email: string;
  password: string;
}

export interface Profile {
  uid: string;
  first_name: string;
  last_name: string;
  role: string;
}

export interface Page<T> {
  items: T[];
  total: number;
  page: number;
  size: number;
  total_amount?: number | 0;
}

export interface CustomerCard {
  front_public_url: string;
  back_public_url: string;
}

export interface BucketFile {
  id: string;
  public_url: string;
}

export interface Customer {
  id?: string | null;
  name: string;
  address: string | null;
  phone: string;
  cin: string;
  driver_license_number: string;
  added_at?: Date | null;
  updated_at?: Date | null;

  cin_card?: CustomerCard | null;
  driver_license_card?: CustomerCard | null;
}

export interface Car {
  id: string | null;
  name: string;
  license_plate: string;
  chassis_number: string;
  total_cost: number;
  monthly_payment: number;
  vignette: number;

  min_daily_rate: number;

  yearly_insurance_rate: number;
  traveled_distance: number;
  monthly_insurance_rate: number;

  average_monthly_cost: number;

  documents_count?: number;

  image_public_url: string;

  next_oil_change_at?: number;

  added_at: Date | null;
  updated_at: Date | null;
}

export interface Reservation {
  id: string | null;
  car_id: string;

  car_name: string | null;
  customer_name: string;
  customer_cin: string;
  phone_number: string;

  from_date: Date;
  number_of_days: number;
  price_per_day: number;
  to_date: Date;

  deposit: number;

  status: string;
}

export interface Rent {
  id: string | null;
  car_id?: string;
  customer_id?: string;
  second_driver_id?: string | null;
  is_flat_fee_rent?: boolean;
  price_per_day?: number;
  number_of_days?: number;
  total?: number;
  delivery_fee?: number;

  amount_paid: number;
  initial_payment_taken_by: string | null;

  amount_left?: number;
  retainer_amount: number;

  retainer_payment_type: string;

  from_date?: Date;
  to_date?: Date;

  car?: Car | null;
  customer?: Customer | null;
  second_driver?: Customer | null;

  contract_public_url?: string | null;

  added_at?: Date | null;
  updated_at?: Date | null;
}

export interface StatsItem {
  name: string;
  value: number;
}

export interface MonthlyStats {
  revenue_in_current_month: number;
  revenue_in_current_month_by_car: [];

  charges_split_in_current_month: StatsItem[];
  total_charges_in_current_month: number;
  number_of_rented_days_in_period: StatsItem[];

  last_update_at: Date;
}

export interface OverAllStats {
  total_number_of_customers: number;
  total_unpaid_amount: number;
  cash_register: number;
  average_monthly_cost_by_car: StatsItem[];
}

export interface MonthlyPayment {
  id: string | null;
  car_id: string;
  date: Date;
  amount: number;
}

export interface RentPayment {
  id: string | null;
  rent_id: string;
  car_id: string;
  date: Date;
  amount: number;
  taken_by_agent_id: string;
  notes: string | null;
}

export interface CarDocument {
  id: string | null;
  car_id?: string | null;
  type: string;
  status: string;
  expiration_date: Date;
  file_public_url?: string | null;
  added_at?: Date;
}

export interface CarCharge {
  id: string | null;
  car_id: string | null;
  type: string;
  amount: number;
  date: Date;
  invoice_file_public_url: string | null;
  do_not_deduct: boolean;
}

export interface CarOilChange {
  id: string | null;
  car_id: string | null;
  at_distance: number;
  amount: number;
  date: Date;
  invoice_file_public_url: string | null;
  do_not_deduct: boolean;
}

export enum ChargeType {
  OIL_CHANGE = "OIL_CHANGE",
  MISCELLANEOUS_CHARGE = "MISCELLANEOUS_CHARGE",
  CAR_CHARGE = "CAR_CHARGE",
}

export interface Charge {
  id: string | null;
  type?: ChargeType;
  label: string;
  amount: number;
  date: Date;
  invoice?: BucketFile | null;
}

export interface InsurancePayment {
  id: string | null;
  amount: number;
  date: Date;
  status: string;
}

export interface InsuranceStats {
  amount_paid: number;
  amount_left: number;
  total: number;
}

export interface RecurringCharge {
  id: string | null;
  label: string;
  amount: number;
  cycle: string;
  at_month: number;
  at_day: number;
  next_charge_at?: Date | null;
  theoretical_last_charge_at?: Date | null;
  last_charge_at?: Date | null;
}

export interface RecurringChargePayment {
  id: string | null;
  amount: number;
  date: Date;
  invoice_file_public_url: string | null;
}

export interface Agent {
  id: string | null;
  name: string;
  email: string;
  salary: number;
  insurance: number;
  type: string;
}

export interface AgentPayment {
  id: string | null;
  agent_id: string;
  amount: number;
  date: Date;
  type: string;
}

export interface Config {
  company_name: string;
  company_phone_number: string;
  company_website: string;

  company_street_address: string;
  company_city: string;
  company_zip: string;

  company_ice: string;
  company_rc: string;
  company_if: string;

  insurance_split: number;
  alertzy_account_key: string | null;
}

export interface BlacklistedProfile {
  id: string | null;

  name: string;
  cin: string | null;
  driver_license_number: string | null;
  city: string | null;
  region: string | null;
  reason: string | null;
}

export interface BlacklistFilters {
  cities: string[];
}

export interface Invoice {
  id: string | null;
  formatted_id?: string;

  customer_id: string;
  customer?: Customer;

  invoice_date: Date;
  vat: number;
  payment_type: string;
  rents?: string[];

  total_ati?: number;
  total_ht?: number;
}

export interface Declaration {
  status: string;
  rejection_reason: string;
  declared_at: Date;
}

export interface Infraction {
  id: string;
  license_plate: string;
  place: string;
  date: Date;
  label: string;
  status: string;

  car: Car;
  rent: Rent;
  customer: Customer;

  image_link: string;
  pdf_link: string;

  declaration: Declaration | null;
}

export interface CashRegisterFunds {
  id?: string;
  label: string;
  amount: number;
  date: Date;
}

export interface CompanyDocument {
  id: string | null;
  label: string;
  file?: BucketFile | null;
  added_at?: Date;
}
