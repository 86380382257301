import {
  BlockStack,
  Card,
  Text,
  IndexTable,
  InlineStack,
  Button,
  Box,
  useIndexResourceState,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { RentPayment } from "../../../../../types/common.types";
import {
  formatDate,
  formatMoney,
  getAgentNameById,
} from "../../../../../helpers/helpers";
import { useRentPayments } from "./hooks/useRentPayments";
import AddRentPaymentModal from "./AddRentPaymentModal";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useDeleteRentPayments } from "./hooks/useDeleteRentPayments";
import { useAgents } from "../../../Agents/hooks/useAgents";

export default function RentPayments({
  rentId,
  carId,
}: {
  rentId: string;
  carId: string;
}) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const columns = [
    { label: t("date") },
    { label: t("amount") },
    { label: t("taken_by") },
    { label: t("notes") },
  ];

  const [addRentPaymentModalActive, setAddRentPaymentModalActive] =
    useState(false);
  const { payments, isFetching, page, setPage, minPage, maxPage } =
    useRentPayments(rentId, 10);
  const { agents } = useAgents(10);
  const deleteRentPayments = useDeleteRentPayments(rentId);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(
    payments.map((payment: RentPayment) => ({ ...payment })),
  );

  const handleDeleteCharges = async () => {
    onShowToast("Deleting Payments", false);

    try {
      await deleteRentPayments(selectedResources);
      await queryClient.invalidateQueries([queryKeysConstants.rents, rentId]);

      clearSelection();
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
    }
  };

  const rowMarkup = payments.map((payment: RentPayment, index) => (
    <IndexTable.Row
      id={payment.id as string}
      key={payment.id}
      position={index}
      selected={selectedResources.includes(payment.id as string)}
    >
      <IndexTable.Cell>{formatDate(payment.date)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(payment.amount)}</IndexTable.Cell>
      <IndexTable.Cell>
        {getAgentNameById(agents, payment.taken_by_agent_id)}
      </IndexTable.Cell>
      <IndexTable.Cell>{payment.notes || "-"}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              {t("payments")}
            </Text>
            <Button
              variant="plain"
              onClick={() => setAddRentPaymentModalActive(true)}
            >
              {t("add")}
            </Button>
          </InlineStack>
        </Box>

        <IndexTable
          resourceName={{
            singular: t("payment"),
            plural: t("payments"),
          }}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          itemCount={payments.length}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          loading={isFetching}
          selectable
          promotedBulkActions={[
            {
              content: t("delete_payments"),
              onAction: handleDeleteCharges,
            },
          ]}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
      </BlockStack>
      <AddRentPaymentModal
        rentId={rentId}
        carId={carId}
        active={addRentPaymentModalActive}
        setActive={setAddRentPaymentModalActive}
      />
    </Card>
  );
}
