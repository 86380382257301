export default {
  customers: "customers",
  user: "user",
  cars: "cars",
  rents: "rentals",
  stats: "stats",
  reservations: "reservations",
  charges: "charges",
  insurance: "insurance",
  recurring_charges: "recurring_charges",
  agents: "agents",
  config: "config",
  blacklist: "blacklist",
  invoices: "invoices",
  infractions: "infractions",
  cashRegister: "cash-register",
  miscellaneous_charges: "miscellaneous-charges",
  company_documents: "company-documents",
};
