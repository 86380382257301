import {
  BlockStack,
  Card,
  Text,
  IndexTable,
  InlineStack,
  Button,
  Box,
  Link,
  useIndexResourceState,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import { CarOilChange } from "../../../../../types/common.types";
import {
  formatDate,
  formatDistance,
  formatMoney,
} from "../../../../../helpers/helpers";
import { useCarOilChanges } from "./hooks/useCarOilChanges";
import AddCarOilChangeModal from "./AddCarOilChangeModal";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useDeleteCarOilChanges } from "./hooks/useDeleteCarOilChanges";

export default function CarOilChanges({ carId }: { carId: string }) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();

  const columns = [
    { label: t("at_distance") },
    { label: t("date") },
    { label: t("amount") },
    { label: t("invoice") },
  ];

  const [modalActive, setModalActive] = useState(false);

  const { minPage, maxPage, isFetching, page, setPage, oilChanges } =
    useCarOilChanges(carId, 10);

  const deleteOilChanges = useDeleteCarOilChanges(carId);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState([]);

  const handleDelete = async () => {
    onShowToast("Deleting Charges", false);

    try {
      await deleteOilChanges(selectedResources);
      await queryClient.invalidateQueries([queryKeysConstants.cars, carId]);

      clearSelection();
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
    }
  };

  const rowMarkup = oilChanges.map((oilChange: CarOilChange, index: number) => (
    <IndexTable.Row
      id={oilChange.id as string}
      key={oilChange.id}
      position={index}
      selected={selectedResources.includes(oilChange.id as string)}
    >
      <IndexTable.Cell>{formatDistance(oilChange.at_distance)}</IndexTable.Cell>
      <IndexTable.Cell>{formatDate(oilChange.date, true)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(oilChange.amount)}</IndexTable.Cell>
      <IndexTable.Cell>
        {oilChange.invoice_file_public_url ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link url={oilChange.invoice_file_public_url} target="_blank">
            Invoice
          </Link>
        ) : (
          "-"
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              {t("oil_changes")}
            </Text>
            <Button variant="plain" onClick={() => setModalActive(true)}>
              {t("add")}
            </Button>
          </InlineStack>
        </Box>

        <IndexTable
          resourceName={{
            singular: t("oil_change"),
            plural: t("oil_changes"),
          }}
          itemCount={oilChanges.length}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          loading={isFetching}
          selectable
          promotedBulkActions={[
            {
              content: t("delete_oil_change"),
              onAction: handleDelete,
            },
          ]}
          onSelectionChange={handleSelectionChange}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          pagination={{
            hasPrevious: page !== minPage,
            hasNext: maxPage > 1 && page !== maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
        <AddCarOilChangeModal
          carId={carId}
          active={modalActive}
          setActive={setModalActive}
        />
      </BlockStack>
    </Card>
  );
}
