import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { CarDocument, CompanyDocument } from "../../../../types/common.types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function addCompanyDocument(
  companyDocument: CompanyDocument,
  file: File,
): Promise<CarDocument> {
  const form = new FormData();

  form.append("document", file);

  return axiosInstance.post(`/company_documents/`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: companyDocument,
  });
}

interface addCarDocumentProps {
  companyDocument: CompanyDocument;
  file: File | null;
}

export function useAddCompanyDocument(): UseMutateAsyncFunction<
  CarDocument,
  unknown,
  addCarDocumentProps,
  unknown
> {
  const { mutateAsync } = useMutation(
    ({ companyDocument, file }: addCarDocumentProps) =>
      addCompanyDocument(companyDocument, file as File),
  );

  return mutateAsync;
}
