import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function deleteDocuments(
  carId: string,
  documentIds: string[],
): Promise<void> {
  return axiosInstance.delete(`/cars/${carId}/documents`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: {
      ids: documentIds.join(","),
    },
  });
}

export function useDeleteDocuments(
  carId: string,
): UseMutateAsyncFunction<void, unknown, string[], unknown> {
  const { mutateAsync } = useMutation((documentIds: string[]) =>
    deleteDocuments(carId, documentIds),
  );

  return mutateAsync;
}
