import {
  IndexTable,
  Page,
  useSetIndexFiltersMode,
  IndexFilters,
  TabProps,
  InlineStack,
  Pagination,
  Card,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useCallback, useContext, useState } from "react";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "@shopify/polaris-icons";
import { formatDate, formatMoney } from "../../../helpers/helpers";
import { useReservations } from "./hooks/useReservations";
import { Reservation } from "../../../types/common.types";
import ReservationModal from "./ReservationModal";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { useDeleteReservations } from "./hooks/useDeleteReservations";

export default function RentsPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();

  const { mode, setMode } = useSetIndexFiltersMode();
  const [selected, setSelected] = useState(0);
  const [status, setStatus] = useState("active");
  const [queryValue, setQueryValue] = useState("");

  const [modalActive, setModalActive] = useState(false);
  const [reservation, setReservation] = useState<Reservation | null>(null);

  const { minPage, maxPage, isFetching, page, setPage, reservations } =
    useReservations(10, queryValue, status);

  const deleteReservations = useDeleteReservations();

  const tabs: TabProps[] = [
    {
      id: "active",
      content: t("active"),
      isLocked: true,
    },
    {
      id: "archived",
      content: t("archived"),
    },
    {
      id: "all",
      content: t("all"),
    },
  ];
  const columns = [
    { label: t("car_name") },
    { label: t("customer_name") },
    { label: t("cin") },
    { label: t("phone") },
    { label: t("number_of_days") },
    { label: t("price_per_day") },
    { label: t("from_date") },
    { label: t("to_date") },
    { label: t("deposit") },
    { label: t("status") },
  ];

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(
    reservations.map((reservation: Reservation) => ({ ...reservation })),
  );

  const handleFiltersQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    [],
  );

  const handleTabChange = useCallback((value: number) => {
    setSelected(value);
    setStatus(tabs[value].id);
  }, []);

  const handleDelete = async () => {
    onShowToast("Deleting Reservations", false);

    try {
      await deleteReservations(selectedResources);
      await queryClient.invalidateQueries([queryKeysConstants.reservations]);

      clearSelection();
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
    }
  };

  const rowMarkup = reservations.map((reservation: Reservation, index) => (
    <IndexTable.Row
      id={reservation.id as string}
      key={reservation.id}
      position={index}
      onClick={() => {
        setReservation(reservation);
        setModalActive(true);
      }}
      selected={selectedResources.includes(reservation.id as string)}
    >
      <IndexTable.Cell>{reservation.car_name}</IndexTable.Cell>
      <IndexTable.Cell>{reservation.customer_name}</IndexTable.Cell>
      <IndexTable.Cell>{reservation.customer_cin}</IndexTable.Cell>
      <IndexTable.Cell>{reservation.phone_number}</IndexTable.Cell>
      <IndexTable.Cell>{reservation.number_of_days}</IndexTable.Cell>
      <IndexTable.Cell>
        {formatMoney(reservation.price_per_day)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatDate(reservation.from_date, false, true)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatDate(reservation.to_date, false, true)}
      </IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(reservation.deposit)}</IndexTable.Cell>
      <IndexTable.Cell>{t(reservation.status.toLowerCase())}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("reservations")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          setModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <IndexFilters
          queryValue={queryValue}
          queryPlaceholder={t("searching")}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => {}}
          cancelAction={{
            onAction: () => {},
            disabled: false,
            loading: false,
          }}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          filters={[]}
          onClearAll={() => {}}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
        />
        <IndexTable
          resourceName={{
            singular: t("reservation"),
            plural: t("reservations"),
          }}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          itemCount={reservations.length}
          loading={isFetching}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          selectable
          promotedBulkActions={[
            {
              icon: DeleteIcon,
              content: t("delete_reservations"),
              onAction: handleDelete,
            },
          ]}
        >
          {rowMarkup}
        </IndexTable>
        <div
          style={{
            borderTop: ".0625rem solid var(--p-border-subdued)",
            margin: "auto",
            padding: "1.5rem 1rem",
          }}
        >
          <InlineStack align="center">
            <Pagination
              hasPrevious={page > minPage}
              hasNext={page < maxPage}
              onPrevious={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
            />
          </InlineStack>
        </div>
      </Card>
      <ReservationModal
        reservation={reservation}
        active={modalActive}
        setActive={(value: boolean) => {
          setModalActive(value);

          if (!value) setReservation(null);
        }}
      />
    </Page>
  );
}
