import { Modal, FormLayout } from "@shopify/polaris";
import { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../componenets/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { CompanyDocument } from "../../../types/common.types";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import FileUpload from "../../../componenets/FileUpload";
import { useAddCompanyDocument } from "./hooks/useAddCompanyDocument";

interface CarModalProps {
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    label: yup.string().required(),
  })
  .required();

export default function NewCompanyDocumentModal({
  active,
  setActive,
}: CarModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { control, handleSubmit, reset } = useForm<CompanyDocument>({
    defaultValues: {
      label: "",
    },
    resolver: yupResolver(schema),
  });
  const addCompanyDocument = useAddCompanyDocument();

  const handleClose = () => {
    reset();

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (companyDocument: CompanyDocument) => {
    if (!file) {
      onShowToast("Document file is required", true);

      return;
    }

    setLoading(true);

    try {
      await addCompanyDocument({
        file: file as File,
        companyDocument,
      });

      await queryClient.invalidateQueries([
        queryKeysConstants.company_documents,
      ]);

      handleClose();

      onShowToast(t("document_saved"), false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={t("add_document")}
      primaryAction={{
        content: t("save"),
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput control={control} name="label" label={t("label")} />
          <FileUpload label={t("upload")} file={file} setFile={setFile} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
