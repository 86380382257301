import { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import { axiosInstance } from "../../../../services/axios.service";
import { Page, RentPayment } from "../../../../types/common.types";
import { getAccessToken } from "../../../../services/auth.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";

async function getRentsPayments(
  fromDate: Date,
  toDate: Date,
  selectedAgents: string[] | undefined,
  selectedCars: string[] | undefined,
  page = 1,
  size = 10,
): Promise<Page<RentPayment>> {
  const { data } = await axiosInstance.get("/rents/payments", {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: {
      from_date: moment(fromDate).format("YYYY-MM-DD"),
      to_date: moment(toDate).format("YYYY-MM-DD"),
      ...(selectedAgents && { selected_agents: selectedAgents.join(",") }),
      ...(selectedCars && { selected_cars: selectedCars.join(",") }),
      size,
      page,
    },
  });
  return data;
}

interface useCarsProps {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  payments: RentPayment[];
  totalAmount: number;
}

export function useRentsPayments(
  size: number,
  fromDate: Date,
  toDate: Date,
  selectedAgents: string[] | undefined,
  selectedCars: string[] | undefined,
): useCarsProps {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    [
      queryKeysConstants.rents,
      "payments",
      fromDate,
      toDate,
      selectedAgents,
      selectedCars,
      page,
      size,
    ],
    () =>
      getRentsPayments(
        fromDate,
        toDate,
        selectedAgents,
        selectedCars,
        page,
        size,
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    payments: data?.items || [],
    totalAmount: data?.total_amount || 0,
  };
}
