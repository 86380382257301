import { useTranslation } from "react-i18next";
import {
  BlockStack,
  Card,
  FormLayout,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
  TextContainer,
} from "@shopify/polaris";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { Config } from "../../../types/common.types";
import { useConfig } from "./hooks/useConfig";
import TextFieldInput from "../../../componenets/TextFieldInput";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { useUpdateConfig } from "./hooks/useUpdateConfig";

const schema = yup
  .object({
    insurance_split: yup.number().required(),
    alertzy_account_key: yup.string().nullable(),
  })
  .required();

export default function SettingsPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { control, reset, handleSubmit } = useForm<Config>({
    defaultValues: {
      company_name: "",
      company_phone_number: "",
      company_website: "",
      company_street_address: "",
      company_city: "",
      company_zip: "",
      company_ice: "",
      company_rc: "",
      company_if: "",
      insurance_split: 1,
      alertzy_account_key: null,
    },
    resolver: yupResolver(schema),
  });
  const { isLoading, config } = useConfig();
  const updateConfig = useUpdateConfig();

  const onHandleSubmit = async (config: Config) => {
    setLoading(true);

    try {
      await updateConfig(config);

      await queryClient.invalidateQueries([queryKeysConstants.config]);

      onShowToast("Config Saved", false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    reset({
      ...config,
    });
  }, [config]);

  if (isLoading)
    return (
      <SkeletonPage fullWidth>
        <Layout>
          <Layout.Section>
            <Card>
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      title={t("settings")}
      primaryAction={{
        content: t("save"),
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap="200">
              <Text as="span" fontWeight="bold">
                {t("company_details")}
              </Text>
              <FormLayout>
                <FormLayout.Group>
                  <TextFieldInput
                    control={control}
                    name="company_name"
                    label={t("name")}
                  />
                  <TextFieldInput
                    control={control}
                    name="company_phone_number"
                    label={t("phone")}
                  />
                  <TextFieldInput
                    control={control}
                    name="company_website"
                    label={t("website")}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextFieldInput
                    control={control}
                    name="company_street_address"
                    label={t("address")}
                  />
                  <TextFieldInput
                    control={control}
                    name="company_city"
                    label={t("city")}
                  />
                  <TextFieldInput
                    control={control}
                    name="company_zip"
                    label={t("zip")}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextFieldInput
                    control={control}
                    name="company_ice"
                    label={t("ICE")}
                  />
                  <TextFieldInput
                    control={control}
                    name="company_rc"
                    label={t("RC")}
                  />
                  <TextFieldInput
                    control={control}
                    name="company_if"
                    label={t("IF")}
                  />
                </FormLayout.Group>
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <FormLayout>
              <TextFieldInput
                control={control}
                name="insurance_split"
                label={t("insurance_split")}
                type="number"
                max={12}
                min={1}
              />
              <TextFieldInput
                control={control}
                name="alertzy_account_key"
                label={t("alertzy_account_key")}
              />
            </FormLayout>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
