import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Car } from "../../../../types/common.types";

async function addCar(carImage: File, car: Car): Promise<Car> {
  const form = new FormData();

  if (carImage) {
    form.append("file", carImage);
  } else {
    form.append("no-file", "true");
  }

  return axiosInstance.post(`/cars/`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: car,
  });
}

interface addCarProps {
  carImage: File;
  car: Car;
}

export function useAddCar(): UseMutateAsyncFunction<
  Car,
  unknown,
  addCarProps,
  unknown
> {
  const { mutateAsync } = useMutation(({ carImage, car }: addCarProps) =>
    addCar(carImage, car),
  );

  return mutateAsync;
}
